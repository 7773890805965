import React, { useEffect, useState } from 'react';
import Image from 'components/atoms/Image/Image';
import { Trans } from '@lingui/macro';
import Carousel from '../../components/Carousel/Carousel';
import { featureLists } from './pricingCardsData';
import uniqid from 'uniqid';

const PricingSection = ({ openForm }) => {
  const [seeAll, SetSeeAll] = useState(false);
  const [lists, setLists] = useState(featureLists);

  useEffect(() => {
    if (seeAll) {
      setLists(featureLists);
    } else {
      const shortLists = [];
      featureLists.forEach((list) => shortLists.push([list[0]]));
      setLists(shortLists);
    }
  }, [seeAll]);

  const carouselSettings = {
    slidesToShow: 3,
    centerMode: true,
    centerPadding: 0,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          arrows: false,
          infinite: false,
        },
      },
    ],
  };

  return (
    <section id="pricing_cards" className="pricing-cards mb-section container scroll-smooth">
      <h2 className="text-center mb-m text-blue-900">
        <Trans>Simple, transparent pricing</Trans>
      </h2>
      <Carousel className="pricing-slider" settings={carouselSettings}>
        <div>
          <div className="w-auto p-l rounded-3xl mx-l mb-xl shadow-15 tablet:max-w-sm tablet:mx-auto">
            <div className="mb-m">
              <h3 className="mb-none">
                <Trans>Fractal FF</Trans>
              </h3>
              <span className="text-xs text-base-300">
                <Trans>Power up your business</Trans>
              </span>
            </div>
            <div className="mb-xl text-base-300">
              <h1 className="mb-none">
                <Trans>Free</Trans>
              </h1>
              <span className="text-xs">
                <Trans>Billed per user</Trans>
              </span>
            </div>
            <div className="mb-xl font-bold">
              <Trans>COMING SOON</Trans>
            </div>
            <div className="mb-xl">
              <FeatureList list={lists[0]} />
            </div>
            <button
              disabled
              className="w-full font-bold text-white bg-base-200 py-xxs rounded-xl border-solid border-2"
            >
              <Trans>Get Started</Trans>
            </button>
          </div>
        </div>
        <div>
          <div className="w-auto p-l rounded-3xl mx-l mb-xl shadow-card tablet:max-w-sm tablet:mx-auto">
            <div className="mb-m">
              <h3 className="mb-none">
                <Trans>Fractal Crowd</Trans>
              </h3>
              <span className="text-xs text-base-300">
                <Trans>Register as a single user</Trans>
              </span>
            </div>
            <div className="mb-[80px] text-blue-900 font-bold">
              <h1 className="mb-none">
                <Trans>$200/month</Trans>
              </h1>
              <span className="text-xs">
                <Trans>Billed per user</Trans>
              </span>
            </div>
            <div className="mb-xl">
              <FeatureList list={lists[1]} />
            </div>
            <button
              className="w-full font-bold text-white bg-yellow-900 py-xxs rounded-xl border-solid border-2"
              onClick={() => openForm('crowd')}
            >
              <Trans>Get Started</Trans>
            </button>
          </div>
        </div>
        <div>
          <div className="w-auto p-l rounded-3xl mx-l mb-xl shadow-15 tablet:max-w-sm tablet:mx-auto">
            <div className="mb-m">
              <h3 className="mb-none">
                <Trans>Fractal Enterprise</Trans>
              </h3>
              <span className="text-xs text-base-300">
                <Trans>Power up your business</Trans>
              </span>
            </div>
            <div className="mb-[98px] font-bold">
              <h1 className="mb-none">
                <Trans>Request a quote</Trans>
              </h1>
            </div>
            <div className="mb-xl">
              <FeatureList list={lists[2]} />
            </div>
            <button
              className="w-full font-bold text-yellow-900 bg-white py-xxs rounded-xl border-solid border-2 border-yellow-900"
              onClick={() => openForm('enterprise')}
            >
              <Trans>Get Started</Trans>
            </button>
          </div>
        </div>
      </Carousel>

      <div className="flex justify-center">
        {seeAll ? (
          <a
            className="text-blue-900 flex items-center mb-none"
            onClick={() => SetSeeAll(!seeAll)}
            href="#pricing_cards"
          >
            <span className="mr-xxs">
              <Trans>hide full plan</Trans>
            </span>
            <Image name="chevron-left" className="w-s -rotate-90" />
          </a>
        ) : (
          <button className="text-blue-900 flex  items-center" onClick={() => SetSeeAll(!seeAll)}>
            <span className="mr-xxs">
              <Trans>see full plan</Trans>
            </span>
            <Image name="chevron-left" className="w-s rotate-90" />
          </button>
        )}
      </div>
    </section>
  );
};

export default PricingSection;

const FeatureList = ({ list }) => {
  if (typeof list === 'undefined') {
    return null;
  }
  return (
    <>
      {list.map((subList, i) => (
        <div className="mb-xxl" key={uniqid('pricing-cards-')}>
          {typeof subList.header !== 'undefined' && (
            <div className="font-bold mb-m">{subList.header}</div>
          )}
          <ul>
            {subList.content.map((item) => (
              <FeatureRow key={uniqid('pricing-cards-row-')} item={item} />
            ))}
          </ul>
        </div>
      ))}
    </>
  );
};

const FeatureRow = ({ item }) => {
  if (typeof item === 'undefined') {
    return null;
  }
  const [key] = Object.keys(item);
  const icon = item[key] ? 'check-mark-blue-round' : 'x-mark-gray-round';
  return (
    <li className="flex mb-s">
      <Image name={icon} className="mr-s w-s shrink-0" />
      <span>{key}</span>
    </li>
  );
};
