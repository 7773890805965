// import "./FractalRoadmap.scss";
import React from 'react';
import uniqid from 'uniqid';
import now from './images/now.svg';
import next from './images/next.svg';
import nowDesktop from './images/nowDesktop.svg';
import nextDesktop from './images/nextDesktop.svg';
import Carousel from '../Carousel';

const SecurityPolicy = () => {
  const carouselSettings = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    initialSlide: 1,
    dotsClass: 'custom-dot-list',
    customPaging: (i) => <button className="custom-dot">{`${carouselData[i].headerName}`}</button>,
  };

  const carouselData = [
    {
      headerName: 'SUPPORTED NOW',
      image: next,
      imageDesktop: nextDesktop,
    },
  ];

  return (
    <div className="roadmap-carousel-wrapper">
      <Carousel settings={carouselSettings}>
        {carouselData.map( ( slide,i ) => (
          <div key={uniqid( 'roadmap-' )} className="roadmap__slide">
            <div
              className="roadmap__image roadmap__image--mobile"
            >
              <img
                src={slide.image}
                alt="roadmap components"
              />
            </div>
            <img
              src={slide.imageDesktop}
              alt="roadmap components"
              className="roadmap__image roadmap__image--desktop"
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default SecurityPolicy;
